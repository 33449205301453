import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Card,
  CardContent,
  Collapse,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toEDNString } from "edn-data";
import React, { useEffect, useState } from "react";
import "./App.css";
import { ChatBubble, ChatInput } from "./chat";
import { RenderPageBlocks } from "./components";

const R = require("ramda");

enum PageContentType {
  NOTEBOOK = "NOTEBOOK",
  SELECT_CONNECT_CARD_TYPE = "SELECT_CONNECT_CARD_TYPE",
  ADD_CONNECT_CARD = "ADD_CONNECT_CARD",
}

const omegaUrl = process.env.REACT_APP_OMEGA_URL;

const queryOmega = async (query: string) => {
  const response = await fetch(`${omegaUrl}/query_clj`, {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: query,
  });
  const jsonResponse = await response.json();
  return jsonResponse["result"];
};

export const queryOmegaSingleResult = async (query: string) => {
  const response = await queryOmega(query);
  return response["data"][0][0];
};

const getHomePage = async () => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-page {"path" "/"} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const getNotePage = async (noteId: any) => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-page {"path" "note/${noteId}"} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const getConnectorTypes = async () => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-connector-types {} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response["connector-types"];
};

const getPullConnectorPage = async (schemaName: any) => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-page {"path" "connector/${schemaName}"} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const getNotebook = async () => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-notes {} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const getConnectors = async () => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/get-connectors {} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  const conns = R.sortBy(R.prop("schema"), response["connectors"]);
  return conns;
};

const deleteConnector: (config: { schema: string }) => Promise<any> = async ({
  schema,
}) => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/delete-connector {"schema" "${schema}"} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const deletePageBlock: (
  pageId: string,
  blockIndex: number
) => Promise<any> = async (pageId, blockIndex) => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/delete-note-block {"note-id" "${pageId}" "block-index" ${blockIndex}} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  console.log("DELETE_PAGE_RESPONSE", response);
  console.log("DELETE_PAGE_RESPONSE_NOTE", response["new-note"]);
  return response["new-note"];
};

const createConnectCard: (config: {
  schema: string;
  name: string;
}) => Promise<any> = async ({ schema, name }) => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/create-connect-card {"schema" "${schema}" "name" "${name}"} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const createConversation = async () => {
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/create-conversation Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const omegaReply: (config: {
  reply: string;
  chatId: string;
}) => Promise<any> = async ({ reply, chatId }) => {
  const ednString = toEDNString({
    map: [
      ["content", reply],
      ["chat-id", chatId],
    ],
  });
  console.log("EDN_STRING", ednString);
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/reply-conversation ${ednString} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e84bfa",
    },
    secondary: {
      main: "#351c75",
    },
  },
});

const AppBarSection: React.FC<{
  drawerWidth: any;
  toggleSideNavDrawer: () => void;
  toggleChatDrawer: () => void;
  drawerOpen: boolean;
}> = ({ toggleChatDrawer, drawerWidth, drawerOpen, toggleSideNavDrawer }) => {
  return (
    <AppBar
      sx={{
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: drawerOpen ? `calc(100% - ${drawerWidth})` : "100%",
        marginLeft: drawerOpen ? `${drawerWidth}` : 0,
      }}
    >
      <Toolbar>
        {!drawerOpen && (
          <ToggleSideNavButton toggleSideNavOpen={toggleSideNavDrawer} />
        )}
        <IconButton edge="start" color="inherit" aria-label="app icon">
          <img src={`omega_logo.png`} alt="app icon" style={{ height: 24 }} />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          aria-label="Open QueryOmega AI Chat"
          onClick={toggleChatDrawer}
          color="primary"
        >
          <AutoAwesomeIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const ChatDrawer: React.FC<{
  messages: any;
  drawerOpen: any;
  toggleDrawer: any;
  addMessageAndRespond: any;
}> = ({ messages, drawerOpen, toggleDrawer, addMessageAndRespond }) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      PaperProps={{
        style: { width: "80%" },
      }}
    >
      <Box
        sx={{ p: 2, height: "100%" }}
        role="presentation"
        display="flex"
        flexDirection={"column"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 2,
          }}
        >
          <IconButton color="primary" onClick={toggleDrawer} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        </Box>
        {messages.map((msg: any, index: number) => {
          return (
            <ChatBubble
              key={index}
              spec={{
                sender: msg.role,
                content: msg.content,
              }}
            />
          );
        })}
        <Box sx={{ display: "flex", flexGrow: 1 }} />
        <ChatInput
          addMessage={({ content, role }) => {
            addMessageAndRespond({ role, content } as any);
          }}
        />
      </Box>
    </Drawer>
  );
};

const ToggleSideNavButton: React.FC<{ toggleSideNavOpen: any }> = ({
  toggleSideNavOpen,
}) => {
  return (
    <Box p={2}>
      <IconButton
        color="primary"
        aria-label="toggle drawer"
        onClick={() => toggleSideNavOpen()}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

const SideNavPullConnector: React.FC<{
  connector: any;
  selectedPullConnector: any;
  setSelectedPullConnector: (schema: any, tableName: any) => void;
  setPageContentType: any;
  getPullConnectorsAndSetState: any;
}> = ({
  connector,
  selectedPullConnector,
  setSelectedPullConnector,
  setPageContentType,
  getPullConnectorsAndSetState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen: any = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <ListItem sx={{ pl: 4 }}>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary={connector["connector-name"]} />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              deleteConnector({ schema: connector?.schema }).then(() => {
                getPullConnectorsAndSetState();
              });
              setPageContentType(PageContentType.NOTEBOOK);
            }}
            color="primary"
            edge="end"
            aria-label="add"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen}>
        {connector.tables.map((table: any, index: any) => {
          return (
            <ListItemButton
              selected={
                selectedPullConnector === `${connector.schema}/${table}`
              }
              onClick={() => setSelectedPullConnector(connector.schema, table)}
              key={index}
              sx={{ pl: 8 }}
            >
              <ListItemText primary={table} />
            </ListItemButton>
          );
        })}
      </Collapse>
    </>
  );
};

const SideNavPullConnectors: React.FC<{
  selectedPullConnector: any;
  setSelectedPullConnector: any;
  connectors: any;
  setPageContentType: (contentType: PageContentType) => void;
  getPullConnectorsAndSetState: any;
}> = ({
  setSelectedPullConnector,
  connectors,
  selectedPullConnector,
  setPageContentType,
  getPullConnectorsAndSetState,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <ListItem>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary="Pull Connectors" />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() =>
              setPageContentType(PageContentType.SELECT_CONNECT_CARD_TYPE)
            }
            color="primary"
            edge="end"
            aria-label="add"
          >
            <AddIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen}>
        <List>
          {connectors.map((connector: any, index: number) => (
            <SideNavPullConnector
              setSelectedPullConnector={setSelectedPullConnector}
              key={index}
              connector={connector}
              selectedPullConnector={selectedPullConnector}
              setPageContentType={setPageContentType}
              getPullConnectorsAndSetState={getPullConnectorsAndSetState}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const SideNavNote: React.FC<{
  page: any;
  selectedPage: any;
  setSelectedPage: any;
}> = ({ page, selectedPage, setSelectedPage }) => {
  return (
    <ListItemButton
      onClick={() => setSelectedPage(page.id)}
      sx={{ pl: 4 }}
      selected={selectedPage === page.id}
    >
      <ListItemText primary={page.name} />
    </ListItemButton>
  );
};

const SideNavNotebook: React.FC<{
  selectedPage: any;
  setSelectedPage: any;
  pages: any;
}> = ({ selectedPage, setSelectedPage, pages }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen: any = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <ListItem>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary="Notebook" />
        {/*
        One day....
        */}
        {/* <ListItemSecondaryAction>
          <IconButton color="primary" edge="end" aria-label="add">
            <AddIcon />
          </IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
      <Collapse in={isOpen}>
        <List>
          {pages.map((page: any, index: number) => (
            <SideNavNote
              setSelectedPage={setSelectedPage}
              key={index}
              page={page}
              selectedPage={selectedPage}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const SideNav: React.FC<{
  drawerOpen: any;
  drawerWidth: any;
  toggleDrawerOpen: any;
  selectedNote: any;
  setSelectedNote: any;
  pages: any;
  connectors: any;
  selectedPullConnector: any;
  setSelectedPullConnector: any;
  setPageContentType: (contentType: PageContentType) => void;
  getPullConnectorsAndSetState: any;
}> = ({
  drawerOpen,
  drawerWidth,
  toggleDrawerOpen,
  selectedNote,
  setSelectedNote,
  pages,
  connectors,
  selectedPullConnector,
  setSelectedPullConnector,
  setPageContentType,
  getPullConnectorsAndSetState,
}) => {
  return (
    <Drawer
      variant="persistent"
      open={drawerOpen}
      sx={{
        width: drawerOpen ? drawerWidth : "0",
        "& .MuiDrawer-paper": {
          width: drawerOpen ? drawerWidth : "0",
          boxSizing: "border-box",
        },
      }}
    >
      {drawerOpen && (
        <ToggleSideNavButton toggleSideNavOpen={toggleDrawerOpen} />
      )}
      <List>
        <SideNavPullConnectors
          connectors={connectors}
          selectedPullConnector={selectedPullConnector}
          setSelectedPullConnector={setSelectedPullConnector}
          setPageContentType={setPageContentType}
          getPullConnectorsAndSetState={getPullConnectorsAndSetState}
        />
        <SideNavNotebook
          selectedPage={selectedNote}
          setSelectedPage={setSelectedNote}
          pages={pages}
        />
      </List>
    </Drawer>
  );
};

const PageContentBox: React.FC<{
  children: React.ReactNode;
  sideNavOpen: any;
  drawerWidth: any;
}> = ({ children, sideNavOpen, drawerWidth }) => {
  return (
    <Box
      position="absolute"
      sx={{
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: sideNavOpen ? `calc(100% - ${drawerWidth})` : "100%",
        marginLeft: sideNavOpen ? `${drawerWidth}` : 0,
        overflow: "scroll",
      }}
    >
      {children}
    </Box>
  );
};

const SelectConnectCardPage: React.FC<{ setSelectedConnectCardType: any }> = ({
  setSelectedConnectCardType,
}) => {
  const [connectCardTypes, setConnectCardTypes] = useState<any>([]);
  console.log({ connectCardTypes });

  useEffect(() => {
    getConnectorTypes().then(setConnectCardTypes);
  }, []);

  return (
    <Grid container spacing={3}>
      {connectCardTypes.map((connectorType: any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={connectorType.id}>
          <Card
            sx={{
              height: "12rem",
              justifyContent: "center",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Avatar
                alt={connectorType.name}
                src={connectorType.icon_url}
                style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
              />
              <Typography variant="body1" style={{ marginTop: 10 }}>
                {connectorType.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => {
                    setSelectedConnectCardType(connectorType.id);
                  }}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const AddConnectCardPage: React.FC<{ selectedConnectCardType: any }> = ({
  selectedConnectCardType,
}) => {
  const [connectCard, setConnectCard] = useState<any>(undefined);
  console.log(connectCard);

  useEffect(() => {
    if (!selectedConnectCardType) return;

    createConnectCard({
      schema: selectedConnectCardType,
      name: "My new Card",
    }).then(setConnectCard);
  }, [selectedConnectCardType]);

  return (
    <Paper sx={{ padding: 3 }}>
      <Link href={connectCard?.url}>Configure Connector</Link>
    </Paper>
  );
};

const PageContent: React.FC<{
  pageContentType: PageContentType;
  deletePageBlock: any;
  setPageContentType: (contentType: PageContentType) => void;
  appState: any;
  selectedNoteId: any;
}> = ({
  pageContentType,
  appState,
  setPageContentType,
  deletePageBlock,
  selectedNoteId,
}) => {
  const [selectedConnectCardType, _setSelectedConnectCardType] =
    useState<any>(undefined);
  const setSelectedConnectCardType = (connectCardType: any) => {
    _setSelectedConnectCardType(connectCardType);
    setPageContentType(PageContentType.ADD_CONNECT_CARD);
  };

  switch (pageContentType) {
    case PageContentType.SELECT_CONNECT_CARD_TYPE:
      return (
        <SelectConnectCardPage
          setSelectedConnectCardType={setSelectedConnectCardType}
        />
      );
    case PageContentType.NOTEBOOK:
      return (
        <RenderPageBlocks
          pageId={selectedNoteId}
          deletePageBlock={deletePageBlock}
          spec={appState}
        />
      );
    case PageContentType.ADD_CONNECT_CARD:
      return (
        <AddConnectCardPage selectedConnectCardType={selectedConnectCardType} />
      );
  }
};

const getUrlSearch = () => {
  const params = new URLSearchParams(window.location.search);
  const paramMap = {} as any;
  params.forEach((value, key) => {
    paramMap[key] = value;
  });
  return paramMap;
};

function App() {
  const [appState, setAppState] = useState<any>({
    title: "",
    blocks: [],
  });
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(true);
  const [chatId, setChatId] = useState<any>();
  const [messages, setMessages] = useState<any>([
    // {
    //   content:
    //     'I created a table named "top_5_attendees" which contains the top 5 attendees based on the number of events they have attended. The table includes the following columns:\n\n- **event_count**: The number of events attended by the attendee.\n- **email**: The email address of the attendee.\n\nThis table helps identify the most frequent attendees in Google Calendar events.\n\nThis is a [link](http://google.com)',
    //   role: "assistant",
    // },
  ]);
  const [notebook, setNotebook] = useState<any>({});
  const [connectors, setConnectors] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>([]);
  const [pageContentType, setPageContentType] = useState<PageContentType>(
    PageContentType.NOTEBOOK
  );
  const search = getUrlSearch();
  const connectCardId = search?.id;

  const pages = R.sortBy(R.prop("id") as any, notebook.pages ?? []);
  const selectedPullConnector =
    selectedPage[0] === "connector" && `${selectedPage[1]}/${selectedPage[2]}`;
  const selectedNote = selectedPage[0] === "note" && selectedPage[1];

  const setSelectedPullConnector = (pullConnector: any, tableName: any) => {
    setSelectedPage(["connector", pullConnector, tableName]);
    setPageContentType(PageContentType.NOTEBOOK);
  };

  const setSelectedNote = (pullConnector: any) => {
    setSelectedPage(["note", pullConnector]);
    setPageContentType(PageContentType.NOTEBOOK);
  };

  const toggleChatDrawer = () => {
    setChatDrawerOpen((chatDrawerOpen) => !chatDrawerOpen);
  };

  const toggleSideNavDrawer = () => {
    setSideNavOpen((sideNavOpen) => !sideNavOpen);
  };

  const addMessage: (msg: {
    role: "assistant" | "user";
    content: string;
  }) => void = (msg) => {
    setMessages((messages: any) => [...messages, msg]);
  };

  const addMessageAndRespond: (msg: {
    role: "assistant" | "user";
    content: string;
  }) => void = (msg) => {
    const { content } = msg;
    addMessage(msg);
    omegaReply({ chatId, reply: content })
      .then(({ reply }: any) =>
        addMessage({ role: "assistant", content: reply })
      )
      .then(() => {
        getNotePageAndSetState(selectedNote);
      });
  };

  const getHomePageAndSetState = () => {
    getHomePage().then(setAppState);
  };

  const getNotePageAndSetState = (noteId: any) => {
    getNotePage(noteId).then(setAppState);
  };

  const getPullConnectorPageAndSetState = (pullConnector: any) => {
    getPullConnectorPage(pullConnector).then(setAppState);
  };

  const getPullConnectorsAndSetState = () => {
    getConnectors().then(setConnectors);
  };

  const deletePageBlockAndSetState = (pageId: string, blockIndex: number) => {
    deletePageBlock(pageId, blockIndex).then(setAppState);
  };

  useEffect(() => {
    setSelectedPage((selectedPage: any) => selectedPage ?? pages[0]?.id);
  }, [pages]);

  useEffect(() => {
    if (!notebook?.pages) return;
    const selectNote = notebook.pages[0].id;
    setSelectedNote(selectNote);
  }, [notebook]);

  useEffect(() => {
    getNotebook().then(setNotebook);
    getConnectors().then(setConnectors);
    getPullConnectorsAndSetState();
    getHomePageAndSetState();
    createConversation().then((res: any) => setChatId(res["chat-id"]));
  }, []);

  useEffect(() => {
    selectedNote && getNotePageAndSetState(selectedNote);
  }, [selectedNote]);

  useEffect(() => {
    selectedPullConnector &&
      getPullConnectorPageAndSetState(selectedPullConnector);
  }, [selectedPullConnector]);

  useEffect(() => {
    if (!connectCardId) return;
    console.log("CONN", connectCardId);
    // setPageContentType(PageContentType.)
  }, [connectCardId]);

  const drawerWidth = "15rem";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box>
          <SideNav
            drawerOpen={sideNavOpen}
            drawerWidth={drawerWidth}
            toggleDrawerOpen={toggleSideNavDrawer}
            selectedNote={selectedNote}
            pages={pages}
            connectors={connectors}
            selectedPullConnector={selectedPullConnector}
            setSelectedPullConnector={setSelectedPullConnector}
            setSelectedNote={setSelectedNote}
            setPageContentType={setPageContentType}
            getPullConnectorsAndSetState={getPullConnectorsAndSetState}
          />
        </Box>
        <PageContentBox drawerWidth={drawerWidth} sideNavOpen={sideNavOpen}>
          <AppBarSection
            drawerWidth={drawerWidth}
            drawerOpen={sideNavOpen}
            toggleChatDrawer={toggleChatDrawer}
            toggleSideNavDrawer={toggleSideNavDrawer}
          />
          <Box sx={{ margin: 6, marginTop: 12 }}>
            <PageContent
              deletePageBlock={deletePageBlockAndSetState}
              appState={appState}
              pageContentType={pageContentType}
              setPageContentType={setPageContentType}
              selectedNoteId={selectedNote}
            />
          </Box>
        </PageContentBox>
      </Box>
      <ChatDrawer
        addMessageAndRespond={addMessageAndRespond}
        drawerOpen={chatDrawerOpen}
        messages={messages}
        toggleDrawer={toggleChatDrawer}
      />
    </ThemeProvider>
  );
}

export default App;
