import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toEDNString } from "edn-data";
import React, { useEffect, useState } from "react";
import { queryOmegaSingleResult } from "./App";
import DeleteIcon from "@mui/icons-material/Delete";

export const runSearchQuery: (config: {
  fileId: any;
}) => Promise<any> = async ({ fileId }) => {
  const ednString = toEDNString({
    map: [["file-id", fileId]],
  });
  // console.log("RUN_SEARCH_INPUT", ednString);
  const query = `
    (datastore Api "omega/query-omega/public/api")
    (Api/search-table ${ednString} Response)
    (return [Response])
  `;
  const response = await queryOmegaSingleResult(query);
  // console.log("RUN_SEARCH_OUTPUT", response);
  return response;
};

export const SearchTable: React.FC<{ spec: any }> = ({
  spec,
}) => {
  const [searchData, setSearchData] = useState<any>(undefined);
  useEffect(() => {
    runSearchQuery({ fileId: spec["data-source"] }).then(setSearchData);
  }, [spec]);
  const table = searchData?.table ?? [];
  const [header, ...rows] = table;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {header?.map((name: any, index: number) => (
              <TableCell key={index}>{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, rowIndex: number) => (
            <TableRow key={rowIndex}>
              {row.map((cell: any, cellIndex: number) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const RenderPageBlocks: React.FC<{
  spec: any;
  deletePageBlock: any;
  pageId: any
}> = ({ spec, deletePageBlock, pageId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        gap: 3,
      }}
    >
      <Typography variant="h4">{spec?.title}</Typography>
      {spec?.blocks.map((block: any, index: number) => (
        <Paper
          elevation={3}
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 3,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">{block?.title}</Typography>
            <IconButton
              color="primary"
              onClick={() => {
                deletePageBlock(pageId, index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <SearchTable
            spec={{ ...block, blockIndex: index }}
          />
        </Paper>
      ))}
    </Box>
  );
};
