import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  IconButton,
  Link,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./App.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const ChatBubble: React.FC<{
  spec: {
    sender: "assistant" | "user";
    content: string;
  };
}> = ({ spec: { sender, content } }) => {
  const isAssistant = sender === "assistant";
  const backGroundColor = (theme: Theme) =>
    isAssistant ? theme.palette.grey[900] : theme.palette.grey[800];
  const justifyContent = isAssistant ? "flex-start" : "flex-end";
  return (
    <Box display="flex" justifyContent={justifyContent} p={1}>
      <Paper
        style={{ borderRadius: "16", padding: "10px" }}
        sx={{
          backgroundColor: backGroundColor,
        }}
      >
        <Typography variant="body1">
          {isAssistant ? (
            <ReactMarkdown
              components={{
                a: ({ node, ...props }) => (
                  <Link href={props.href} target={"_blank"} color="primary">
                    {props.children}
                  </Link>
                ),
              }}
              remarkPlugins={[remarkGfm]}
            >
              {content}
            </ReactMarkdown>
          ) : (
            content
          )}
        </Typography>
      </Paper>
    </Box>
  );
};

export const ChatInput: React.FC<{ addMessage: (msg: any) => void }> = ({
  addMessage,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendMessage = () => {
    if (inputValue === "") {
      return;
    }
    addMessage({ role: "user", content: inputValue });
    setInputValue("");
  };

  return (
    <Box
      display="flex"
      component="form"
      onSubmit={(event: React.FormEvent) => {
        event.preventDefault();
        sendMessage();
      }}
    >
      <TextField
        onChange={handleInputChange}
        value={inputValue}
        sx={{ display: "flex", flexGrow: 1, paddingRight: 2 }}
      />
      <IconButton
        color="primary"
        onClick={() => {
          sendMessage();
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};
